.description {
  display: flex;
  align-items: center;
  margin-left: 47px;
  margin-top: 100px;

  &__box {
    width: 680px;
    margin-right: 200px;

    &-title {
      font-family:"Poiret+One";
      font-size: 58px;
      left: 91px;
      margin-bottom: 50px;
    }

    &-text {
      font-family:"Roboto";
      font-size: 34px;
      font-weight: 400;
      line-height: 48px;
    }
  }

  &-img {
    width: 500px;
    height: auto;
    margin-right: 50px;
  }

  @media (max-width: 1350px) {

    &__box {
      margin-right: 100px;

      &-title {
        font-size: 38px;
      }

      &-text {
        font-size: 28px;
        line-height: 38px;
      }
    }

    &-img {
      width: 400px;
    }
  }

  @media (max-width: 960px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (max-width: 376px) {
    
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 40px;

    &__box {
      margin-right: 0px;
      margin-bottom: 20px;

      &-title {
        font-size: 30px;
        margin-bottom: 20px;
      }

      &-text {
        font-size: 21px;
        line-height: 25px;
      }
    }

    &-img {
      width: 300px;
      margin-right: 0px;
    }
  }
}