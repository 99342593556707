.slider {
  position: relative;
  width: 90%;
  margin: auto;
  overflow: hidden;
  margin-top: 70px;

  &-window {
    width: 90%;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
  }

  &-content {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }

  &-img {
    width: 350px;
    height: 325px;
    border-radius: 30px;
    object-fit: fill;
  }

  &-button {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 50%;
    background-image: url(../../../public/img/arrow.svg);
    background-size: contain;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    z-index: 10;
  }
  
  &-button:focus {
    outline: none;
  }
  
  &-button:first-of-type {
    transform: rotate(180deg);
    left: 50px;
  }
  
  &-button:last-of-type {
    right: 50px;
  }

  @media (max-width: 1440px) { 

    &-button:first-of-type {
      left: 40px;
    }
    
    &-button:last-of-type {
      right: 40px;
    }
  }

  @media (max-width: 1366px) {
    &-button:first-of-type {
      left: 30px;
    }
    
    &-button:last-of-type {
      right: 30px;
    }
  }


  @media (max-width: 1320px) {

    &-button:first-of-type {
      left: 100px;
    }
    
    &-button:last-of-type {
      right: 100px;
    }

    &-window {
      width: 75%;
    }

    &-img {
      width: 250px;
      height: 255px;
    }
  }

  @media (max-width: 1200px) { 
    &-img {
      width: 300px;
      height: 305px;
    }
  }

  @media (max-width: 970px) { 
    &-img {
      width: 250px;
      height: 255px;
    }
  }

  @media (max-width: 768px) { 
    &-img {
      width: 370px;
      height: 375px;
    }
  }

  @media (max-width: 560px) {

    &-window {
      width: 100%;
    }

    &-button:first-of-type {
      left: 0px;
    }
    
    &-button:last-of-type {
      right: 0px;
    }

    &-img {
      width: 300px;
      height: 305px;
    }
  }

}

.slide {
  display: flex;
  min-width: 33.33%; /* Отображаем три слайда одновременно */
  box-sizing: border-box;
  justify-content: center;

  &:nth-child(3) {
    margin-right: 0px;
  }

  @media (max-width: 1200px) { 
    min-width: 50%;
  }

  @media (max-width: 768px) { 
    min-width: 100%;
  }

}
