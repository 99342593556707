.info {
  width: 90%;
  font-family: 'Roboto';
  margin: auto;
  margin-top: 78px;

  p {
    font-size: 50px; 
    line-height: 63px;
    font-weight: 600;
  }


  @media (max-width: 768px) {

    p {
      font-size: 40px;
    }
    
  }

  @media (max-width: 376px) {

    display: flex;
    text-align: center;
    margin-top: 40px;

    p {
      font-size: 30px;
    }
    
  }
}