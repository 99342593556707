@import '../../style/style.scss';
@import url(https://fonts.google.com/share?selection.family=Poiret+One);


.app {

  &__header {
    display: flex;
    width: inherit;
    justify-content: space-between;
    align-items: center;
    height: 126px;
    z-index: 2;
    background: #000000;

    &-burger {
      display: none;
      position: absolute;
      width: 24px;
      height: 16px;
      top: 10px;
      right: 10px;
      background-image: url(../../../public/img/burger.svg);
      background-color: transparent;
      background-repeat: no-repeat;
      border: none;
    }

    &-logo {
      margin-left: 79px;
      font-family:"Poiret+One";
      font-style: normal;
      font-weight: 400;
      font-size: 60px;
      line-height: 82px;
      color: #FFFFFF;
    }

    @media (max-width: 1350px) {

      &-logo {
        margin-left: 60px;
        font-size: 50px;
      }
    }

    @media (max-width: 1200px) {

      &-logo {
        margin-left: 40px;
        font-size: 40px;
      }
    }
  }

  &__menu {
    display: flex;
    flex-wrap: wrap;
    margin-right: 93px;

    ul {
      display: -webkit-inline-box;
    }
    li {
      margin-right: 33px;
      font-family:"Poiret+One";
      font-style: normal;
      font-weight: 400;
      font-size: 25px;
      line-height: 36px;
      color: #FFFFFF;
      border-bottom: 2px solid transparent;
      
      &:hover {
        border-bottom: 2px solid #FFFFFF;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  @media (max-width: 1500px) {
    &__menu {
      margin-right: 40px;
  
      li {
        font-size: 22px;
      }
    }

  }

  @media (max-width: 1350px) {

    &__menu {
  
      li {
        font-size: 21px;
      }
    }
  }

  @media (max-width: 1200px) {

    &__menu {
      margin-right: 30px;

      li {
        font-size: 19px;
      }
    }
  }

  @media (max-width: 1000px) {

    &__menu {
      margin-right: 20px;

      li {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 760px) {

    &__header {
      flex-direction: column;

      &-logo {
        margin-left: 0px;
      }

    }

    &__menu {
      display: none;
    }

    &__menu-active {
      display: flex;
  
      ul {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        li {
          margin-right: 0px;
        }
      }
    }  

    &__header-box {
      height: auto;
    }

    &__header-box-active {
      background-image: none;

      .app__header-content {
        display: none;
      }
    }

    &__header {
      height: auto;

      &-burger {
        display: flex;
      }
  
      &-active-burger {
        background-image: url(../../../public/img/close.svg);
        background-position: center;
      }

      &-content {
        left: 16px;
      }

      &-btn {
        display: none;
      }
    }  
  }
}