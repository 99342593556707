@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
  display: inline-block;
  &:hover {
      text-decoration: none;
      color: inherit;
  }
}

p {
  margin: 0;
  padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; 
}

.app {
  margin: 0 auto;
  padding: 0px;
  position: relative;
}
