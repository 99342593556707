.footer {
  margin-top: 100px;

  & p {
    background-color: #000000;
    font-family:"Roboto";
    font-weight: 400;
    font-size: 26px;
    line-height: 65px;
    padding-left: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    color: #FFFFFF;
  }

  @media (max-width: 600px) {

    margin-top: 40px;

    & p {
      font-size: 15px;
      line-height: 35px;
      padding-left: 10px;
    }
  }
}