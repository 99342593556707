.contacts {
  width: 80%;
  margin: auto;
  margin-top: 100px;

  &-title {
    font-family:"Poiret+One";
    font-size: 53px;
    line-height: 53px;
    font-weight: 600;
  }

  &__box {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;

    &-phone {
      font-family:"Roboto";
      font-size: 40px;
      line-height: 98px;
      font-weight: 400;
    }
  }

  @media (max-width: 760px) { 

    margin-top: 60px;

    &-title {
      font-size: 36px;
      line-height: 43px;
    }

    &__box {
      margin-top: 0px;
      
      &-phone {
        font-size: 30px;
        line-height: 58px;
      }
    }

  }

  @media (max-width: 600px) {
    
    margin-top: 40px;

    &-title {
      font-size: 30px;
      line-height: 43px;
    }

    &__box {
      margin-top: 0px;
      flex-direction: column;
      
      &-phone {
        font-size: 25px;
        line-height: 58px;
      }
    }

  }
}